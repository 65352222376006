@font-face {
  font-family: Amazon Ember;
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("amazon-ember-bold.8282cead.woff2") format("woff2"), url("amazon-ember-bold.54689419.woff") format("woff"), url("amazon-ember-bold.f62b43c5.ttf") format("truetype");
}

@font-face {
  font-family: Amazon Ember;
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: url("amazon-ember-light.e9abe195.woff2") format("woff2"), url("amazon-ember-light.3e3ab1a2.woff") format("woff"), url("amazon-ember-light.24699309.ttf") format("truetype");
}

@font-face {
  font-family: Amazon Ember;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("amazon-ember-regular.c6947246.woff2") format("woff2"), url("amazon-ember-regular.b16943ef.woff") format("woff"), url("amazon-ember-regular.4008429f.ttf") format("truetype");
}
/*# sourceMappingURL=index.a61a0862.css.map */
