@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url("amazon-ember-bold.woff2") format("woff2"), url("amazon-ember-bold.woff") format("woff"), url("amazon-ember-bold.ttf") format("truetype");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 200;
  font-display: auto;
  src: url("amazon-ember-light.woff2") format("woff2"), url("amazon-ember-light.woff") format("woff"), url("amazon-ember-light.ttf") format("truetype");
}

@font-face {
  font-family: "Amazon Ember";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("amazon-ember-regular.woff2") format("woff2"), url("amazon-ember-regular.woff") format("woff"), url("amazon-ember-regular.ttf") format("truetype");
}